import React, { Component, Fragment } from 'react';
import { Table, Row, Col, Card, CardBody, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { useParams } from 'react-router-dom';

import { Auth } from 'Service';
import { SlipToggle } from '../../Redux/Reducers'
import { CONSTANT } from 'Constants';
import { MessageBox } from 'Components';
import { Http, API, _, Utils } from 'Helpers';


class BetslipOpenBet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // BetslipType: CONSTANT.MATCHED,
      BetslipType: '',
      filter: 1,
      OpenBetList: [],
      Offset: CONSTANT.CURRENT_OFFSET,
      current_page: 1,
      totalPage: 0,
      itemsCountPerPage: CONSTANT.ITEMS_PERPAGE_25,
      pageRangeDisplayed: 5,
      windowSizeScreen: false,
      betsMatch: false,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
      selectedFancyM: '',
      selectedFancyMOption: [{ value: '0', label: 'All' }],
      selectedFancy: '',
      selectedFancyOption: [{ value: '0', label: 'All' }],
      selectedMarket: '',
      selectedMarketOption: [{ value: '0', label: 'All' }],
      selectedMOMarket: '',
      selectedMOMarketOption: [{ value: '0', label: 'All' }],
      selectedBMMarket: '',
      selectedBMMarketOption: [{ value: '0', label: 'All' }],
    }
    this.getOpenBet = this.getOpenBet.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  // Handlers
  // switchBetting = (BetslipType, filter) => {
  //   if (BetslipType == this.state.BetslipType) {
  //     this.setState({
  //       BetslipType: '',
  //       OpenBetList: [],
  //     })
  //   } else {
  //     this.setState({
  //       BetslipType,
  //       filter,
  //       OpenBetList: [],
  //       totalPage: 0,
  //       current_page: 1,
  //     }, () => {
  //       if (Auth.currentUserValue) {
  //         this.getOpenBet()
  //       }
  //     })
  //   }
  // }

  switchBetting = (BetslipType, filter, istrue) => {
    this.setState({
      allItems: false,
      filter: filter,
      OpenBetList: [],
      totalPage: 0,
      current_page: 1,
      selectedMarket: '',
      selectedFancy: ''

    }, () => {

      if (istrue) {
        this.setState({
          selectedFancy: this.state.selectedFancyOption[0],
          selectedFancyM: this.state.selectedFancyMOption[0],
          selectedMarket: this.state.selectedMarketOption[0],
          selectedMOMarket: this.state.selectedMOMarketOption[0],
          selectedBMMarket: this.state.selectedBMMarketOption[0],
        })
      }

    })
    if (BetslipType == this.state.BetslipType)
      this.setState({ betsMatch: !this.state.betsMatch, BetslipType: '' }, () => { this.getOpenBet(true) })
    else if (BetslipType != this.state.BetslipType)
      this.setState({ betsMatch: true, BetslipType }, () => { this.getOpenBet(true) })


  }

  // getOpenBet = () => {
  //   const { filter, current_page } = this.state
  //   let param = {
  //     "items_perpage": CONSTANT.ITEMS_PERPAGE_SM,
  //     "current_page": current_page,
  //     "filter": filter,
  //     "event_id": window.location.pathname.split("/").pop()
  //   }

  //   this.setState({ posting: true })
  //   Http.post(API.OPEN_BETS, param).then(response => {
  //     this.setState({
  //       posting: false,
  //       OpenBetList: response.data.open_bets,
  //       totalPage: response.data.total_records,
  //     }, () => {
  //     })
  //   }).catch(error => {
  //     this.setState({ posting: false })
  //   });
  // }

  getOpenBet = (type) => {
    const { allItems } = this.state;
    let param = {
      "items_perpage": allItems ? CONSTANT.ALL_ITEMS : CONSTANT.ITEMS_PERPAGE_25,
      "current_page": this.state.current_page,
      "filter": this.state.filter,
      "event_id": window.location.pathname.split("/").pop(),
      "session_id": this.state.filter == 3 ? this.state.selectedFancyM.session_id : this.state.selectedFancy.session_id,
      "market_event_id": this.state.filter == 1 ? this.state.selectedMOMarket.value : this.state.filter == 2 ? this.state.selectedBMMarket.value : this.state.filter == 4 ? this.state.selectedMarket.value : this.state.filter == 3 ? this.state.selectedFancyM.value : this.state.selectedFancy.value,
      "child_session_type": this.state.filter == 3 ? this.state.selectedFancyM.child_session_type_id : this.state.selectedFancy.child_session_type_id,
      "odd_id": this.state.filter == 1 ? this.state.selectedMOMarket.value : this.state.filter == 2 ? this.state.selectedBMMarket.value : this.state.filter == 5 ? this.state.selectedMarket.value : this.state.filter == 3 ? this.state.selectedFancyM.session_id : this.state.selectedFancy.session_id,
    }
    this.setState({ posting: true })
    if (this.state.betsMatch)
      Http.post(API.OPEN_BETS, param).then(response => {
        if (response.data.open_bets.length > 0) {
          var markets = response.data.open_bets;

          if (this.state.filter == 1 && type) {
            if (!_.isEmpty(markets)) {
              var selectedMOMarketOption = [{ value: '0', label: 'All' }];
              _.map(markets, (market) => {
                let index = -1;
                for (let i = 0; i < selectedMOMarketOption.length; i++) {
                  if (selectedMOMarketOption[i].value === market.market_event_id) {
                    index = i;
                  }
                }

                if (index > -1) {
                } else {
                  selectedMOMarketOption.push({ value: market.market_event_id, label: Number(market.is_main_market) == 1 ? market.market_name : Number(market.is_diamond_feed) == 1 ? "MATCH ODDS 1 (FAST BET)" : Number(market.is_sky_feed) == 1 ? "MATCH ODDS 2 (FAST BET)" : Number(market.is_central_feed) == 1 ? "MATCH ODDS 3 (FAST BET)" : "MATCH ODDS 2 (FAST BET)" })
                }
              })

              this.setState({ selectedMOMarketOption }, () => {
                if (_.isEmpty(this.state.selectedMOMarket)) {
                  this.setState({ selectedMOMarket: this.state.selectedMOMarketOption[0] })
                }
              })
            }
          }

          if (this.state.filter == 2 && type) {
            if (!_.isEmpty(markets)) {
              var selectedBMMarketOption = [{ value: '0', label: 'All' }];
              _.map(markets, (market) => {
                let index = -1;
                for (let i = 0; i < selectedBMMarketOption.length; i++) {
                  if (selectedBMMarketOption[i].value === market.market_event_id) {
                    index = i;
                  }
                }

                if (index > -1) {
                } else {
                  selectedBMMarketOption.push({ value: market.market_event_id, label: Number(market.is_main_market) == 1 ? market.market_name : Number(market.is_diamond_feed) == 1 ? "BOOKMAKER 1 (fast bet 0 sec)" : Number(market.is_sky_feed) == 1 ? "BOOKMAKER 2 (fast bet 0 sec)" : Number(market.is_central_feed) == 1 ? "BOOKMAKER 3 (fast bet 0 sec)" : "BOOKMAKER 0 COM" })
                }
              })

              this.setState({ selectedBMMarketOption }, () => {
                if (_.isEmpty(this.state.selectedBMMarket)) {
                  this.setState({ selectedBMMarket: this.state.selectedBMMarketOption[0] })
                }
              })
            }
          }

          if (this.state.filter == 5 && type) {
            if (!_.isEmpty(markets)) {
              var selectedMarketOption = [{ value: '0', label: 'All' }];
              _.map(markets, (market) => {
                let index = -1;
                for (let i = 0; i < selectedMarketOption.length; i++) {
                  if (selectedMarketOption[i].value === market.market_event_id) {
                    index = i;
                  }
                }

                if (index > -1) {
                } else {
                  selectedMarketOption.push({ value: market.market_event_id, label: Number(market.is_diamond_feed) == 1 && Number(market.market_type) == 4 ? "MATCH ODDS 4 (FAST BET)" : market.market_name })

                }
              })

              this.setState({ selectedMarketOption }, () => {
                if (_.isEmpty(this.state.selectedMarket)) {
                  this.setState({ selectedMarket: this.state.selectedMarketOption[0] })
                }
              })
            }
          }

          if (this.state.filter == 3 && type) {
            if (!_.isEmpty(markets)) {
              var selectedFancyMOption = [{ value: '0', label: 'All', session_id: '' }];
              _.map(markets, (market) => {
                if (Number(market.child_session_type_id) == 0) {
                  let index = -1;
                  for (let i = 0; i < selectedFancyMOption.length; i++) {
                    if (selectedFancyMOption[i].value === market.session_id) {
                      index = i;
                    }
                  }

                  if (index > -1) {
                  } else {
                    selectedFancyMOption.push({ value: market.session_id, label: market.market_name, session_id: market.session_id, child_session_type_id: market.child_session_type_id })
                  }

                }
              })

              this.setState({ selectedFancyMOption }, () => {
                if (_.isEmpty(this.state.selectedFancyM)) {
                  this.setState({ selectedFancyM: this.state.selectedFancyMOption[0] })
                }
              })
            }
          }

          if (this.state.filter == 4 && type) {
            if (!_.isEmpty(markets)) {
              var selectedFancyOption = [{ value: '0', label: 'All', session_id: '' }];
              _.map(markets, (market) => {
                if (Number(market.child_session_type_id) !== 0) {
                  let index = -1;
                  for (let i = 0; i < selectedFancyOption.length; i++) {
                    if (selectedFancyOption[i].value === market.session_id) {
                      index = i;
                    }
                  }

                  if (index > -1) {
                  } else {
                    selectedFancyOption.push({ value: market.session_id, label: market.market_name, session_id: market.session_id, child_session_type_id: market.child_session_type_id })
                  }
                }
              })

              this.setState({ selectedFancyOption }, () => {
                if (_.isEmpty(this.state.selectedFancy)) {
                  this.setState({ selectedFancy: this.state.selectedFancyOption[0] })
                }
              })
            }
          }

          if (this.state.filter == 5) {
            if (!_.isUndefined(this.state.selectedMarket) && this.state.selectedMarket.label !== 'All') {
              markets = response.data.open_bets
            }
            else {
              markets = response.data.open_bets
            }
          }
          else if (this.state.filter == 4) {
            if (!_.isUndefined(this.state.selectedFancy) && this.state.selectedFancy.label !== 'All') {
              markets = response.data.open_bets
            }
            else {
              markets = response.data.open_bets
            }
          }
          else {
            markets = response.data.open_bets
          }

          this.setState({
            posting: false,
            totalPage: response.data.total_records,
            allItems: false,
            OpenBetList: Number(this.state.current_page) === 1 ? markets : (!_.isEmpty(markets) ? [...this.state.OpenBetList, ...markets] : this.state.OpenBetList),
            hasBetMore: !_.isEmpty(markets) || (_.size(markets) === CONSTANT.ITEMS_PERPAGE_25),
          })
        }
        else {
          this.setState({ posting: false })
        }
      }).catch(error => {
        this.setState({ posting: false })
      });
  }



  fancyFilterChange = (e) => {
    let op = this.state.selectedFancyOption;
    let name = _.filter(op, (data) => {
      if (data.value == e.target.value)
        return data
    })
    this.setState({
      selectedFancy: name[0],
    }, () => {
      this.setState({ OpenBetList: [], current_page: 1, totalPage: 0 }, () => {
        this.getOpenBet();
      })
    })
  }
  fancyMFilterChange = (e) => {
    let op = this.state.selectedFancyMOption;
    let name = _.filter(op, (data) => {
      if (data.value == e.target.value)
        return data
    })
    this.setState({
      selectedFancyM: name[0],
    }, () => {
      this.setState({ OpenBetList: [], current_page: 1, totalPage: 0 }, () => {
        this.getOpenBet();
      })
    })
  }

  marketMOFilterChange = (e) => {
    let op = this.state.selectedMOMarketOption;
    let name = _.filter(op, (data) => {
      if (data.value == e.target.value)
        return data
    })
    this.setState({ selectedMOMarket: name[0] }, () => {
      this.setState({ OpenBetList: [], current_page: 1, totalPage: 0 }, () => {
        this.getOpenBet();
      })
    })
  }

  marketBMFilterChange = (e) => {
    let op = this.state.selectedBMMarketOption;
    let name = _.filter(op, (data) => {
      if (data.value == e.target.value)
        return data
    })
    this.setState({ selectedBMMarket: name[0] }, () => {
      this.setState({ OpenBetList: [], current_page: 1, totalPage: 0 }, () => {
        this.getOpenBet();
      })
    })
  }

  marketFilterChange = (e) => {
    let op = this.state.selectedMarketOption;
    let name = _.filter(op, (data) => {
      if (data.value == e.target.value)
        return data
    })
    this.setState({ selectedMarket: name[0] }, () => {
      this.setState({ OpenBetList: [], current_page: 1, totalPage: 0 }, () => {
        this.getOpenBet();
      })
    })
  }

  handlePageChange = (current_page) => {
    this.setState({ current_page }, () => this.getOpenBet())
  }

  getIndex(index) {
    if (this.state.current_page === 1) {
      // return (this.state.totalPage - (index))
      return (index + 1)
    } else {
      // return (this.state.totalPage - (((this.state.current_page - 1) * CONSTANT.ITEMS_PERPAGE_25) + (index)))
      return ((this.state.current_page * CONSTANT.ITEMS_PERPAGE_25 - CONSTANT.ITEMS_PERPAGE_25 + 1) + index)
    }
  }

  deleteUnmatchBet = (betslip_uid, index) => {
    this.setState({ posting: true })
    let param = {
      "betslip_uid": betslip_uid,
    }
    Http.post(API.CANCEL_UM_BET, param).then(response => {
      let tempArr = this.state.OpenBetList
      tempArr.splice(index, 1);
      this.setState({
        posting: false,
        OpenBetList: tempArr,
      })
      Utils.notify(response.message)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }


  updateDimensions = () => {
    this.setState({
      windowSizeScreen: window.innerWidth > 767.98
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    if (Auth.currentUserValue) {
      this.getOpenBet()
    }
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
    this.updateDimensions();
  }


  // Life cycle
  render() {
    const { openCollapse, SlipToggle, is_betslip_drawer, t } = this.props;
    const { BetslipType, OpenBetList, totalPage, current_page, itemsCountPerPage, pageRangeDisplayed, windowSizeScreen, isThemeGradient,
      selectedMarketOption, selectedMarket, selectedMOMarket, selectedMOMarketOption, selectedBMMarket, selectedBMMarketOption, selectedFancyM, selectedFancyMOption,
      selectedFancy, selectedFancyOption
    } = this.state;
    const currentUser = Auth.currentUserValue
    const PaginationProps = {
      activePage: current_page,
      itemsCountPerPage: itemsCountPerPage,
      pageRangeDisplayed: pageRangeDisplayed,
      totalItemsCount: totalPage,
      onChange: this.handlePageChange,
    }

    return (
      currentUser ? (
        // !windowSizeScreen ? (
        //   <Fragment>
        //     <Row className="mobile-view-listofbet">
        //       <Col><b className="mb-0 col-title">{t('MATCH BETS')}</b></Col>
        //     </Row>
        //     <Row className="mobile-view-listofbet">
        //       <Col>
        //         <Card>
        //           <CardBody className="p-2">
        //             <Row>
        //               <Col>
        //                 <div className="betslip-tabs">
        //                   <span onClick={() => this.switchBetting(CONSTANT.MATCHED, 1)}  {...{ className: `${BetslipType === CONSTANT.MATCHED ? 'active' : ''}` }}>{t('MATCHED ODDS')}</span>
        //                   <span onClick={() => this.switchBetting(CONSTANT.FANCY, 3)} {...{ className: `${BetslipType === CONSTANT.FANCY ? 'active' : ''}` }}>{t('FANCY + SESSION')}</span>
        //                 </div>
        //               </Col>
        //             </Row>
        //             <Row>
        //               <Col>
        //                 {
        //                   !_.isEmpty(OpenBetList) ?
        //                     <Table className="table-bets mt-2">
        //                       <thead>
        //                         <tr>
        //                           <th>{t('NO')}</th>
        //                           <th style={{ width: `40%` }}>{t('RUNNER')}</th>
        //                           {BetslipType === CONSTANT.FANCY &&
        //                             <th>{t('RUN')}</th>}
        //                           <th>{t('RATE')}</th>
        //                           <th>{t('AMOUNT')}</th>
        //                           <th style={{ width: `55px` }}>{t('MODE')}</th>
        //                           {BetslipType === CONSTANT.UNMATCHED &&
        //                             <th className="text-center">{t('ACTION')}</th>
        //                           }
        //                         </tr>
        //                       </thead>
        //                       <tbody>
        //                         {
        //                           _.map(OpenBetList, (item, idx) => {
        //                             return (
        //                               <tr key={idx} className={(BetslipType === CONSTANT.FANCY) ? (item.betting_type == 1 ? "lay" : "back") : (item.betting_type == 1 ? "back" : "lay")}>
        //                                 <td>{this.getIndex(idx)}</td>
        //                                 <td>
        //                                   <span className="block">
        //                                     {item.market_runners !== null ?
        //                                       _.map(item.market_runners, (ritm, rid) => {
        //                                         return (
        //                                           item.selection_id == ritm.selectionId ? ritm.runnerName : ''
        //                                         )
        //                                       }) : item.market_name}
        //                                   </span>
        //                                 </td>
        //                                 {
        //                                   BetslipType === CONSTANT.FANCY &&
        //                                   <td>{item.line}</td>
        //                                 }
        //                                 <td>
        //                                   {
        //                                     Number(item.bookmaker_type) !== 3 ?
        //                                       (BetslipType === CONSTANT.MATCHED ? Utils.TwoDecimalVal(item.price - 1) : Utils.TwoDecimalVal(item.price)) :
        //                                       Utils.TwoDecimalVal(Number(item.price) - 1)
        //                                   }
        //                                 </td>
        //                                 <td>{item.stake}</td>
        //                                 <td className="text-uppercase">{!_.isUndefined(item.child_session_type_id) && !_.isNull(item.child_session_type_id) && (Number(item.child_session_type_id) === 10) ? (item.betting_type == 2 ? t("EVEN") : t("ODD")) : (Number(item.bookmaker_type) !== 3 ? (item.betting_type == 1 ? t("Lagai") : t("Khai")) : (item.betting_type == 2 ? t("No") : t("Yes")))}</td>
        //                                 {
        //                                   BetslipType === CONSTANT.UNMATCHED &&
        //                                   <td className="text-center"><a onClick={() => { if (window.confirm(t('Are you sure you want to delete this bet?'))) { this.deleteUnmatchBet(item.betslip_uid, idx) } }} className="delete-unmatch-row sm"><i className="icon-trash"></i></a></td>
        //                                 }
        //                               </tr>
        //                             )
        //                           })
        //                         }
        //                       </tbody>
        //                     </Table>
        //                     :
        //                     <MessageBox paragraph={t(CONSTANT.FIXTURE_BLANK_HEADING)} />
        //                 }
        //                 {
        //                   totalPage >= 5 &&
        //                   <div className="text-center pagination-sm">
        //                     <Pagination {...PaginationProps} />
        //                   </div>
        //                 }
        //               </Col>
        //             </Row>
        //           </CardBody>
        //         </Card>
        //       </Col>
        //     </Row>
        //   </Fragment>
        // ) :


        (<div {...{ className: `betslip-wrap betslip-open-bet${CONSTANT.ONLY_SINGLE_BET ? ' only-single-bet' : ''}` }}>
          <div {...{ className: `betslip-container  ${BetslipType === CONSTANT.FANCY ? 'mobile-fancy' : ''}` }}>
            {/* <div className="betslip-header">
              {t('Match Bets')} */}
            {/* {
             is_betslip_drawer &&
             <Fragment>
               {
                 (openCollapse === 2) ?
                   <i className="icon-up" />
                   :
                   <i className="icon-down" />
               }
             </Fragment>
           } */}
            {/* </div> */}
            {/* <Collapse isOpen={is_betslip_drawer === false ? true : openCollapse === 2}> */}
            <div className={"betslip-tabs " + (isThemeGradient ? "bg-gradient-4" : "")}>
              <span onClick={() => this.switchBetting(CONSTANT.MATCHED, 1, true)}  {...{ className: `${BetslipType === CONSTANT.MATCHED ? 'active' : ''}` }}>{t('Match Odd')}</span>
              {/* <span onClick={() => this.switchBetting(CONSTANT.UNMATCHED,2)} {...{ className: `${BetslipType === CONSTANT.UNMATCHED ? 'active' : ''}` }}>{t('Unmatched')}</span> */}
              {/* <span onClick={() => this.switchBetting(CONSTANT.FANCY,3)} {...{ className: `${BetslipType === CONSTANT.FANCY ? 'active' : ''}` }}>{t('Fancy + Session')}</span> */}

              <span onClick={() => this.switchBetting(CONSTANT.BOOKMAKER, 2, true)} {...{ className: `${BetslipType === CONSTANT.BOOKMAKER ? 'active' : ''}` }}>{t('BOOKMAKER')}</span>

              <span onClick={() => this.switchBetting(CONSTANT.FANCY, 3, true)} {...{ className: `${BetslipType === CONSTANT.FANCY ? 'active' : ''}` }}>{t('FANCY')}</span>

              <span onClick={() => this.switchBetting(CONSTANT.OTH_FAN, 4, true)} {...{ className: `${BetslipType === CONSTANT.OTH_FAN ? 'active' : ''}` }}>{t('OTH. FAN.')}</span>

              <span onClick={() => this.switchBetting(CONSTANT.OTH_MKT, 5, true)} {...{ className: `${BetslipType === CONSTANT.OTH_MKT ? 'active' : ''}` }}>{t('OTH. MKT.')}</span>


            </div>
            <div className={"betslip-body " + (!_.isEmpty(OpenBetList) && !_.isEmpty(BetslipType) && totalPage >= 5 && is_betslip_drawer ? "mb-5 pb-3" : "")}>
              <Row className="mx-0 py-2">
                {/* <Col className={(this.state.filter == 4 || this.state.filter == 5) ? "col-6 text-center py-2" : "text-center py-2"}>
                        <Button
                          onClick={() => this.getAllOpenBets()}
                          color="primary"
                          size="sm">VIEW ALL {this.state.BetslipType == CONSTANT.MATCHED ? "MATCH BETS" : (this.state.BetslipType == CONSTANT.BOOKMAKER ? "BOOKMAKER BETS" : ((this.state.BetslipType == CONSTANT.FANCY ? "FANCY BETS" : "OTHER BETS")))}</Button>
                      </Col> */}
                {
                  (this.state.filter == 4 || this.state.filter == 5 || this.state.filter == 3) &&
                  <Col className="col-8 my-auto">
                    {
                      (this.state.BetslipType == CONSTANT.OTH_FAN) &&
                      <Input
                        type="select"
                        className='fancy-select'
                        name={selectedFancy.label}
                        bsSize="sm"
                        onChange={this.fancyFilterChange}
                      >
                        {_.map(selectedFancyOption, (data, dataKey) => {
                          return (
                            <option value={data.value} key={'fancy_select_' + dataKey}>{data.label}</option>
                          )
                        })}
                      </Input>
                    }
                    {
                      (this.state.BetslipType == CONSTANT.OTH_MKT) &&
                      <Input
                        type="select"
                        className='market-select'
                        bsSize="sm"
                        name={selectedMarket.label}
                        onChange={this.marketFilterChange}
                      >
                        {_.map(selectedMarketOption, (data, dataKey) => {
                          return (
                            <option value={data.value} className="pointer" key={'market_select_' + dataKey}>{data.label}</option>
                          )
                        })}
                      </Input>

                    }

                    {
                      (this.state.BetslipType == CONSTANT.FANCY) &&
                      <Input
                        type="select"
                        className='fancy-select'
                        name={selectedFancyM.label}
                        bsSize="sm"
                        onChange={this.fancyMFilterChange}
                      >
                        {_.map(selectedFancyMOption, (data, dataKey) => {
                          return (
                            <option value={data.value} key={'fancy_select_' + dataKey}>{data.label}</option>
                          )
                        })}
                      </Input>
                    }
                  </Col>
                }



                {
                  (this.state.filter == 1) &&
                  <Col className="col-8 my-auto">

                    {
                      (this.state.BetslipType == CONSTANT.MATCHED) &&
                      <Input
                        type="select"
                        className='market-select'
                        bsSize="sm"
                        name={selectedMOMarket.label}
                        onChange={this.marketMOFilterChange}
                      >
                        {_.map(selectedMOMarketOption, (data, dataKey) => {
                          return (
                            <option value={data.value} className="pointer" key={'market_select_' + dataKey}>{data.label}</option>
                          )
                        })}
                      </Input>

                    }

                  </Col>
                }

                {
                  (this.state.filter == 2) &&
                  <Col className="col-8 my-auto">
                    {
                      (this.state.BetslipType == CONSTANT.BOOKMAKER) &&
                      <Input
                        type="select"
                        className='market-select'
                        bsSize="sm"
                        name={selectedBMMarket.label}
                        onChange={this.marketBMFilterChange}
                      >
                        {_.map(selectedBMMarketOption, (data, dataKey) => {
                          return (
                            <option value={data.value} className="pointer" key={'market_select_' + dataKey}>{data.label}</option>
                          )
                        })}
                      </Input>

                    }
                  </Col>
                }

              </Row>
              {
                !_.isEmpty(OpenBetList) && !_.isEmpty(BetslipType) &&
                <Table className="table-bets m-0" responsive>
                  <thead>
                    <tr>
                      <th>{t('S.no')}</th>
                      <th style={{ width: `30%` }}>{t('Runner')}</th>
                      <th>{t('Market Name')}</th>
                      {BetslipType === CONSTANT.FANCY &&
                        <th>{t('Run')}</th>}
                      {BetslipType === CONSTANT.OTH_FAN &&
                        <th>{t('Run')}</th>}
                      <th>{t('Odds')}</th>

                      <th>{t('Stack')}</th>
                      <th>{t('Mode')}</th>
                      <th>{t('P&l')}</th>
                      <th>{t('Date & Time')}</th>
                      {BetslipType === CONSTANT.UNMATCHED &&
                        <th className="text-center">{t('Action')}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>

                    {_.map(OpenBetList, (item, idx) => {
                      return (
                        <tr key={idx} className={(BetslipType === CONSTANT.FANCY) ? (item.betting_type == 1 ? "back" : "lay") : (item.betting_type == 1 ? "back" : "lay")}>
                          <td>{this.getIndex(idx)}</td>
                          <td>
                            {/* <span className="block">{item.event_name}</span> */}
                            <span className="block">
                              {/* {item.market_name}
                              { ' - '} */}
                              {item.market_runners !== null ?
                                _.map(item.market_runners, (ritm, rid) => {
                                  return (
                                    item.selection_id == ritm.selectionId ? ritm.runnerName : ''
                                  )
                                }) : item.market_name}
                            </span>
                            {/* <span className="block">{Utils.dateTimeFormat(item.created_date, 'DD/MM/YYYY, h:mm A')}</span> */}
                          </td>
                          <td>
                            {
                              Number(item.market_type) === 1 ?
                                (Number(item.is_main_market) == 1 ? item.market_name
                                  : Number(item.is_diamond_feed) == 1 ? "MATCH ODDS 1 (FAST BET)"
                                    : Number(item.is_sky_feed) == 1 ? "MATCH ODDS 2 (FAST BET)"
                                      : Number(item.is_central_feed) == 1 ? "MATCH ODDS 3 (FAST BET)"
                                        : "MATCH ODDS 2 (FAST BET)")
                                : Number(item.market_type) === 2 ? (
                                  Number(item.is_main_market) == 1 ? item.market_name
                                    : Number(item.is_diamond_feed) == 1 ? "BOOKMAKER 1 (fast bet 0 sec)"
                                      : Number(item.is_sky_feed) == 1 ? "BOOKMAKER 2 (fast bet 0 sec)"
                                        : Number(item.is_central_feed) == 1 ? "BOOKMAKER 3 (fast bet 0 sec)"
                                          : "BOOKMAKER 0 COM"

                                ) :
                                  Number(item.is_diamond_feed) == 1 && Number(item.market_type) == 4 ? "MATCH ODDS 4 (FAST BET)"
                                    : item.market_name

                            }
                          </td>
                          {BetslipType === CONSTANT.FANCY &&
                            <td>{item.line}</td>}
                          {BetslipType === CONSTANT.OTH_FAN &&
                            <td>{item.line}</td>}
                          <td>
                            {/* {
                              Number(item.bookmaker_type) !== 3 ?
                                (BetslipType === CONSTANT.MATCHED ? Utils.TwoDecimalVal(item.price - 1) : Utils.TwoDecimalVal(item.price)) :
                                Utils.TwoDecimalVal(Number(item.price) - 1)
                            } */}
                            {Utils.TwoDecimalVal(Number(item.price) - 1)}
                          </td>

                          <td>{item.stake}</td>
                          <td className="text-uppercase">{!_.isUndefined(item.child_session_type_id) && !_.isNull(item.child_session_type_id) && (Number(item.child_session_type_id) === 10) ? (item.betting_type == 2 ? t("EVEN") : t("ODD")) : (Number(item.bookmaker_type) !== 3 ? (item.betting_type == 1 ? t("Lagai") : t("Khai")) : (item.betting_type == 2 ? t("No") : t("Yes")))}</td>
                          <td>{_.round(item.pp, 2).toFixed(2)}</td>
                          <td className='text-nowrap'>{Utils.getDateTimeFormat(item.created_date, "DD MMM YYYY HH:mm:ss")}</td>
                          {
                            BetslipType === CONSTANT.UNMATCHED &&
                            <td className="text-center"><a onClick={() => { if (window.confirm(t('Are you sure you want to delete this bet?'))) { this.deleteUnmatchBet(item.betslip_uid, idx) } }} className="delete-unmatch-row sm"><i className="icon-trash"></i></a></td>
                          }
                        </tr>

                      )
                    })
                    }

                  </tbody>
                </Table>
                // :
                // <MessageBox paragraph={t(CONSTANT.FIXTURE_BLANK_HEADING)} />
              }
              {/* {
                !_.isEmpty(OpenBetList) && !_.isEmpty(BetslipType) && totalPage >= 25 &&
                <div className="text-center mt20 pagination-sm">
                  <Pagination {...PaginationProps} />
                </div>
              } */}
            </div>

            {/* </Collapse> */}
          </div>
        </div>))
        :
        (null)
    )
  }
}

BetslipOpenBet.defaultProps = {

}

const BetslipOpenBetTrans = withTranslation()(BetslipOpenBet)

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse
  };
}

const mapDispatchToProps = dispatch => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipOpenBetTrans);
