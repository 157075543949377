/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { API, Http, Socket, Utils, _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Row, Col, CardBody, Card, Collapse } from "reactstrap";
import { CONSTANT, SocketEvent, Images } from 'Constants';
import { Switch, Betslip, } from 'Components';
import { Auth } from 'Service';
import { SwitchModal } from 'Modals';


class MarketFixtureDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_betslip_drawer: true,
      running: false,
      Init: true,
      posting: false,
      isFixtureDetailsChanged: false,
      odd_id: "",
      betslip_lastupdate: new Date().valueOf(),
      SwitchModalShow: false,
      MarketList: this.props.MarketList,
      EventDetails: this.props.EventDetails,
      recently_changed: [],
      AppMasterData: Utils.getMasterData(),
      exchangeMarketShow: [],
      marketStackMin: this.props.marketStackMin,
      marketStackMax: this.props.marketStackMax,
      bookMakerStackMin: this.props.bookMakerStackMin,
      bookMakerStackMax: this.props.bookMakerStackMax,
      selectedBetslip: '',
      selectedBetslipType: '',
      selectedMarketID: '',
      button_threenine: false,
      SelectedOdds: {},
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
      showMOMarket: true,
      showBMMarket: true,
    }
    this.refreshComponent = this.refreshComponent.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.MarketList !== prevProps.MarketList) {
      if (!_.isUndefined(this.props.MarketList) && !_.isNull(this.props.MarketList)) {
        this.setState({ MarketList: this.props.MarketList })
      }
    }
    if (this.props.EventDetails !== prevProps.EventDetails) {
      if (!_.isUndefined(this.props.EventDetails) && !_.isNull(this.props.EventDetails)) {
        this.setState({ EventDetails: this.props.EventDetails })
      }
    }
  }

  switchmodal = (name, market_event_id, isFor) => {
    this.setState({
      [name]: true,
      market_event_id: market_event_id,
      isFor: isFor,
    })
  }

  callback2 = () => {
    if (this.state.isFor == "one") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "two") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "three") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "four") {
      this.changeMarketstatus(this.state.market_event_id)
    }
    this.switchmodalClose()
  }
  switchmodalClose = () => {
    this.setState({ SwitchModalShow: false })
  }
  setSecTimeout(res) {
    const { AppMasterData, button_threenine } = this.state;
    if (Number(res.commentary_button_id) == 39) {
      let change = AppMasterData;
      this.setState({ button_threenine: true })
      _.map(change.commentary_buttons, (item, index) => {
        if (res.commentary_button_id == item.button_id) {
          setTimeout(() =>
            this.setState({ button_threenine: false })
            , Number(item.bet_close_time) * 1000)
        }
      })
    }
  }
  updateStackMinMax = () => {
    const { EventDetails, AppMasterData } = this.state;
    if (AppMasterData && !_.isEmpty(AppMasterData) && AppMasterData.sports_list && !_.isEmpty(AppMasterData.sports_list) && AppMasterData.sports_list.exchange_betting && !_.isEmpty(AppMasterData.sports_list.exchange_betting) && EventDetails && !_.isEmpty(EventDetails) && EventDetails.sports_id && !_.isEmpty(EventDetails.sports_id)) {
      _.forEach(AppMasterData.sports_list.exchange_betting, item => {
        if (Number(item.sports_id) === Number(EventDetails.sports_id)) {
          if (item.min_odd_limit && !_.isEmpty(item.min_odd_limit))
            this.setState({ marketStackMin: Number(item.min_odd_limit) });
          if (item.max_odd_limit && !_.isEmpty(item.max_odd_limit))
            this.setState({ marketStackMax: Number(item.max_odd_limit) });
          if (item.min_bookmaker_stake && !_.isEmpty(item.min_bookmaker_stake))
            this.setState({ bookMakerStackMin: Number(item.min_bookmaker_stake) });
          if (item.max_bookmaker_stake && !_.isEmpty(item.max_bookmaker_stake))
            this.setState({ bookMakerStackMax: Number(item.max_bookmaker_stake) });
        }
      })
    }
    if (EventDetails && !_.isEmpty(EventDetails)) {
      if (EventDetails.min_odd_limit && !_.isEmpty(EventDetails.min_odd_limit))
        this.setState({ marketStackMin: Number(EventDetails.min_odd_limit) });
      if (EventDetails.max_odd_limit && !_.isEmpty(EventDetails.max_odd_limit))
        this.setState({ marketStackMax: Number(EventDetails.max_odd_limit) });
      if (EventDetails.min_bookmaker_stake && !_.isEmpty(EventDetails.min_bookmaker_stake))
        this.setState({ bookMakerStackMin: Number(EventDetails.min_bookmaker_stake) });
      if (EventDetails.max_bookmaker_stake && !_.isEmpty(EventDetails.max_bookmaker_stake))
        this.setState({ bookMakerStackMax: Number(EventDetails.max_bookmaker_stake) });
    }
  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  SocketGlobalSettingHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    this.setState({
      AppMasterData: { ...AppMasterData, ...res }
    });
  }

  SocketBetCLoseTimeUpdate = (res) => {
    const { AppMasterData } = this.state;
    if (Number(res.button_id) == 39) {
      let change = AppMasterData;
      _.map(change.commentary_buttons, (item, index) => {
        if (res.button_id == item.button_id) {
          item.bet_close_time = res.bet_close_time
          this.setState({ AppMasterData: change })
        }
      })
    }
  }

  SocketDiamondMarketUpdated = (res = {}) => {
    const { MarketRoomEmit, EventDetails } = this.props;
    if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
      const _this = this;
      if (res.market_type == "betfair") {
        _.map(res.data, (item) => {
          MarketRoomEmit(res.market_id);
          _this.SocketMarkethandler(item)
        })
      }
      else if (res.market_type == "bookmaker") {
        _.map(res.data, (item) => {
          MarketRoomEmit(res.market_id);
          _this.SocketBookMarkerMarkethandler(item)
        })
      }
    }
  }

  SocketMatchCommentaryUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { EventDetails } = this.state;

      if (EventDetails.event_id == res.event_id) {
        this.setState({
          EventDetails: { ...EventDetails, ...res }
        }, () => { this.updateStackMinMax(); });

      }
    }
  }

  callSoketEvents = () => {
    const { MarketRoomEmit, EventDetails } = this.props;
    const _this = this;
    Socket.on(SocketEvent.MARKET, function (res) {
      if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
        MarketRoomEmit(res.market_id);
        _this.SocketMarkethandler(res)
      }
    })
    // Socket.on(SocketEvent.STATUSUPDATEDMK, function (res) {
    //   if (!_.isEmpty(res)) {
    //     _this.SocketSwitchHandler(res)
    //   }
    // })
    Socket.on(SocketEvent.BOOKMARKER_MARKET_UPDATED, function (res) {
      if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
        MarketRoomEmit(res.market_id);
        _this.SocketBookMarkerMarkethandler(res)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.DIAMOND_MARKET_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketDiamondMarketUpdated(res)
      }
    })
    Socket.on(SocketEvent.MATCH_COMMENTARY_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMatchCommentaryUpdated(res)
        if (Number(res.commentary_button_id) == 39) {
          _this.setSecTimeout(res)
        }
      }
    })
    Socket.on(SocketEvent.CENTRAL_MARKET_UPDATED, function (res) {
      // if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
      //   MarketRoomEmit(res.market_id);
      //   _this.SocketMarkethandler(res)
      // }
      if (!_.isEmpty(res)) {
        MarketRoomEmit(res.market_id);
        _this.SocketMarkethandler(res)
      }
    })
    Socket.on(SocketEvent.BET_CLOSE_TIME, function (res) {
      if (!_.isEmpty(res))
        _this.SocketBetCLoseTimeUpdate(res)
    })
    Socket.on(SocketEvent.MARKET_COMMENTRY, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMarketCommentry(res);
      }
    })
  }



  // Life cycle
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.callSoketEvents()
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (!_.isUndefined(this.props.mkt_rooms) && !_.isNull(this.props.mkt_rooms)) {
      Socket.emit('unsubscribe', this.props.mkt_rooms);
    }
    Socket.off(SocketEvent.MARKET)
    //Socket.off(SocketEvent.STATUSUPDATEDMK)
    Socket.off(SocketEvent.BOOKMARKER_MARKET_UPDATED)
    Socket.off(SocketEvent.GLOBAL_SETTING)
    Socket.off(SocketEvent.DIAMOND_MARKET_UPDATED)
    Socket.off(SocketEvent.MATCH_COMMENTARY_UPDATED)
    Socket.off(SocketEvent.CENTRAL_MARKET_UPDATED)
    Socket.off(SocketEvent.BET_CLOSE_TIME)
    Socket.off(SocketEvent.MARKET_COMMENTRY)

    this.setState = () => {
      return;
    };
  }

  SocketMarketCommentry = (res = {}) => {
    const { EventDetails } = this.props;
    if (EventDetails.event_id == res.event_id) {
      const { MarketList } = this.state;
      const elementsMainMarketIndex = MarketList.findIndex(element => element.market_id == res.market_id);
      if (elementsMainMarketIndex !== -1) {
        let tempArr = [...MarketList]
        tempArr[elementsMainMarketIndex] = { ...tempArr[elementsMainMarketIndex], commentary_btnid: res.commentary_btnid }
        this.setState({ MarketList: tempArr })
      }
    }
  }

  SocketSwitchHandler = (res = {}) => {
    const { EventDetails } = this.state;
    if (!_.isNull(res.event_id)) {
      if (res.event_id == EventDetails.event_id) {
        this.refreshComponent();
      }
    }
  }

  SocketMarkethandler = (res = {}) => {
    const { MarketList, recently_changed } = this.state
    let newMarketList = _.map(MarketList, item => {
      if (item.market_id == res.market_id) {
        let new_recently_changed = recently_changed
        let item_runners_odds = JSON.parse(item.runners_odds)

        let new_runners_odds = _.map(JSON.parse(res.runners_odds), (odds, idx) => {

          let blankObj = { price: '', size: '' }
          switch (odds.ex.availableToBack.length) {
            case 0:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj, blankObj]
              break;
            case 1:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj]
              break;
            case 2:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj]
              break;
            default:
              break;
          }
          switch (odds.ex.availableToLay.length) {
            case 0:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj, blankObj]
              break;
            case 1:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj]
              break;
            case 2:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj]
              break;
            default:
              break;
          }

          if (item_runners_odds[idx] && item_runners_odds[idx].ex.selectionId == odds.ex.selectionId) {
            let new_availableToBack = _.map(odds.ex.availableToBack, (o, back_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].price) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].price !== o.price
                )
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].size) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].size !== o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_back_' + back_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            let new_availableToLay = _.map(odds.ex.availableToLay, (o, lay_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].price) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].price !== o.price)
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].size) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].size !== o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_lay_' + lay_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            return { ...odds, ex: { ...odds.ex, availableToBack: new_availableToBack, availableToLay: new_availableToLay } }
          }

          return odds
        })

        if (item.status !== res.status || item.odd_status !== res.odd_status) {
          this.setState({
            isChanged: true
          })
        }
        return {
          ...item,
          status: res.status,
          odd_status: res.odd_status,
          runners_odds: JSON.stringify(new_runners_odds)
        };
      }
      return item
    })

    if (this.state.isChanged) {
      this.setState({ MarketList: newMarketList }, () => {
        this.setState({ isChanged: false });
      })
    }
  }

  SocketBookMarkerMarkethandler = (res = {}) => {
    const { MarketList, recently_changed } = this.state
    let newMarketList = _.map(MarketList, item => {
      if (item.market_id == res.market_id) {
        let new_recently_changed = recently_changed
        let item_runners_odds = JSON.parse(item.runners_odds)

        let new_runners_odds = _.map(JSON.parse(res.runners_odds), (odds, idx) => {

          let blankObj = { price: '', size: '' }
          switch (odds.ex.availableToBack.length) {
            case 0:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj, blankObj]
              break;
            case 1:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj, blankObj]
              break;
            case 2:
              odds.ex.availableToBack = [...odds.ex.availableToBack, blankObj]
              break;
            default:
              break;
          }
          switch (odds.ex.availableToLay.length) {
            case 0:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj, blankObj]
              break;
            case 1:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj, blankObj]
              break;
            case 2:
              odds.ex.availableToLay = [...odds.ex.availableToLay, blankObj]
              break;
            default:
              break;
          }

          if (item_runners_odds[idx] && item_runners_odds[idx].ex.selectionId == odds.ex.selectionId) {
            let new_availableToBack = _.map(odds.ex.availableToBack, (o, back_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].price) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].price !== o.price
                )
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToBack) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToBack[back_key].size) &&
                  item_runners_odds[idx].ex.availableToBack[back_key].size !== o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_back_' + back_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            let new_availableToLay = _.map(odds.ex.availableToLay, (o, lay_key) => {
              if (
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].price) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].price !== o.price)
                ||
                (
                  !_.isEmpty(item_runners_odds[idx].ex.availableToLay) &&
                  !_.isUndefined(item_runners_odds[idx].ex.availableToLay[lay_key].size) &&
                  item_runners_odds[idx].ex.availableToLay[lay_key].size !== o.size
                )
              ) {
                new_recently_changed = [...new_recently_changed, JSON.stringify(odds.selectionId) + '_lay_' + lay_key]
                this.setState({
                  isChanged: true,
                  recently_changed: new_recently_changed
                })
                return o
              }
              return o
            })

            return { ...odds, ex: { ...odds.ex, availableToBack: new_availableToBack, availableToLay: new_availableToLay } }
          }

          return odds
        })

        if (item.status !== res.status) {
          this.setState({
            isChanged: true
          })
        }
        return {
          ...item,
          status: res.status,
          is_diamond: Number(res.is_diamond) === 1 ? 1 : 0,
          runners_odds: JSON.stringify(new_runners_odds)
        };
      }
      return item
    })

    if (this.state.isChanged) {
      this.setState({ MarketList: newMarketList }, () => {
        this.setState({ isChanged: false });
      })
    }
  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    if (_.isEmpty(recently_changed)) return ''
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }

  reinitiateComponent = () => {
    const { reinitiateComponent, location, is_inplay } = this.props
    const searchParams = new URLSearchParams(location.search);
    if (is_inplay) {
      reinitiateComponent(searchParams.get('sports'), true)
    } else {
      reinitiateComponent(searchParams.get('sports'))
    }
  }

  refreshComponent = () => {
    const { MarketRoomEmit, EventDetails } = this.props;
    const _this = this;
    Socket.on(SocketEvent.MARKET, function (res) {
      if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
        MarketRoomEmit(res.market_id);
        _this.SocketMarkethandler(res);
      }
    })
  }

  changeMarketstatus = (market_event_id) => {
    const { exchangeMarketShow } = this.state;
    let newExchangeMarketShow = exchangeMarketShow;
    _.map(exchangeMarketShow, (item, i) => {
      if (item.market_event_id == market_event_id)
        newExchangeMarketShow[i] = { ...item, show: !item.show }
    })
    this.setState({ exchangeMarketShow: newExchangeMarketShow })
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.WinlossObject) && !_.isNull(nextProps.WinlossObject) && !_.isUndefined(nextProps.WinlossObject.lastupdate) && !_.isNull(nextProps.WinlossObject.lastupdate)) {
      const { WinlossObject } = this.props
      if (nextProps.WinlossObject.lastupdate !== WinlossObject.lastupdate) {
        if (!nextProps.WinlossObject.is_place_bet) {
          switch (nextProps.WinlossObject.type) {
            case 0:
              this.UpdateWinloss(nextProps.WinlossObject)
              break;
            case 1:
              this.RunTimeUpdateWinloss(nextProps.WinlossObject)
              break;
            case 2:
              this.ResetWinloss(nextProps.WinlossObject)
              break;
            default:
              break;
          }
        } else {
          this.ResetWinloss(nextProps.WinlossObject)
        }
      }
    }
  }
  UpdateWinloss = (obj) => {
    const { MarketList } = this.state
    let newMarketList = _.map(MarketList, (item) => {
      if (item.market_event_id === obj.market_event_id) {
        let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
          return {
            ..._obj,
            winloss: obj.win_loss[_obj.selectionId]
          }
        })
        return { ...item, runners: JSON.stringify(new_runners) }
      }
      return item
    })
    this.setState({ MarketList: newMarketList })
  }

  ResetWinloss = (obj) => {
    const { MarketList } = this.state
    let newMarketList = _.map(MarketList, (item) => {
      let new_runners = _.map(JSON.parse(item.runners), (_obj) => {
        return {
          ..._obj,
          temp_winloss: undefined
        }
      })
      return { ...item, runners: JSON.stringify(new_runners) }
    })
    this.setState({ MarketList: newMarketList })
  }


  RunTimeUpdateWinloss = ({
    market_event_id,
    selectionId,
    stack,
    betting_type,
    pp,
    liability,
    bookmaker_type,
    session_id
  }) => {
    const { MarketList } = this.state
    if (bookmaker_type === CONSTANT.BOOKMAKER_TYPE['exchange_betting']) {
      let newMarketList = _.map(MarketList, (item) => {
        if (item.market_event_id === market_event_id) {
          let new_runners = _.map(JSON.parse(item.runners), (_object) => {
            let _obj = _.cloneDeep(_object)
            if (betting_type === 1) {
              if (_obj.selectionId == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(pp) }
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
              }
            } else if (betting_type === 2) {
              if (_obj.selectionId == selectionId) {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) - Number(liability) }
              } else {
                _obj = { ..._obj, temp_winloss: Number(_obj.winloss) + Number(stack) }
              }
            }
            return _obj
          })
          return { ...item, runners: JSON.stringify(new_runners) }
        }
        return item
      })
      this.setState({ MarketList: newMarketList })
    }
  }

  reloadMatchDetailsPage = () => {
    window.location.reload()
  }

  closeMarketBetSlip = () => {
    this.setState({ selectedBetslip: '', selectedBetslipType: '', selectedMarketID: '' }, () => {
      const { reloadBetslipOpenBet, openBetSlipMarketId } = this.props;
      reloadBetslipOpenBet();
      openBetSlipMarketId('');
    })
  }

  openMarketBetSlip = (market_id, selectionId) => {
    const { openBetSlipMarketId } = this.props;
    openBetSlipMarketId(market_id);
    this.setState({
      selectedMarketID: market_id,
      selectedBetslip: selectionId,
    })
  }
  _oddsActiveClass = (market_bet_id, market_event_id, betting_type) => {
    let { SelectedOdds } = this.state
    return _.some(SelectedOdds, (odd) => {
      return odd.picked_odd_id === market_bet_id + '_' + market_event_id + '_' + betting_type
    })
  }
  handleOptionChange = (option) => {
    let { SelectedOdds } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;

    delete odd.ex;
    SelectedOdds[0] = {
      picked_odd_id: odd.market_bet_id + '_' + fixture.market_event_id + '_' + betting_type,
      market_bet_id: odd.market_bet_id,
      picked_odd_price: betting_type === 1 ? odd.price : odd.lay_price,
      picked_odd: { ...odd, betting_type, ...selected_odd },
      stack_value: '',
      ...fixture,
    }
    this.setState({
      SelectedOdds,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.BETSLIP['exchange_betting'], this.state.SelectedOdds)
    })
  }

  handleRemoveSlip = (key) => {
    this.setState({
      SelectedOdds: [],
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.BETSLIP['exchange_betting'], this.state.SelectedOdds)
    })
  }

  clearAllBet = (BetslipType) => {
    this.setState({
      ...('exchange_betting' === BetslipType && { "SelectedOdds": {} }),
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.removeBetSlip(CONSTANT.BETSLIP[BetslipType])
      Utils.removeMultiBetSlip(CONSTANT.BETSLIP[BetslipType])
    })
  }

  render() {
    const {
      t,
      openBetSlipid,
    } = this.props
    const {
      MarketList,
      EventDetails,
      SelectedOdds,
      betslip_lastupdate,
      is_betslip_drawer,
      odd_id,
      AppMasterData,
      marketStackMin,
      marketStackMax,
      bookMakerStackMin,
      bookMakerStackMax,
      SwitchModalShow,
      button_threenine,
      isThemeGradient
    } = this.state
    const SwitchModalProps = {
      UserProps: '',
      isOpen: SwitchModalShow,
      message: "Are you sure you want to proceed?",
      title: "Confirm",
      callback: () => this.callback2(),
    }
    const currentUser = Auth.currentUserValue;

    const BetslipProps = {
      EventDetails,
      SelectedOdds,
      betslip_lastupdate,
      page_id: 'exchange_betting',
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      closeMarketBetSlip: this.closeMarketBetSlip,
    }

    return (
      <Fragment>

        {
          !_.isUndefined(MarketList) && !_.isNull(MarketList) && !_.isEmpty(MarketList) &&
          <div className="mb-2 market-container-new layback-odd-new">
            <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
              <Col className='d-flex align-items-center col-12 justify-content-between'>
                <div className={"d-block text-uppercase text-nowrap  text-white ml-4 " + (is_betslip_drawer ? "font11" : "font18")}>Match Odds Market</div>
                <div className='mr-3'>
                  <span className={this.state.showMOMarket ? "icon-up" : "icon-down"} onClick={() => { this.setState({ showMOMarket: !this.state.showMOMarket }) }}></span>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={this.state.showMOMarket}>
              {
                _.map(MarketList, (item, key) => {

                  var marketStatus = true;
                  _.map(this.state.exchangeMarketShow, status => {
                    if (status.market_event_id == item.market_event_id)
                      marketStatus = status.show;
                  });
                  let checked_lowest_amount_back = 0;
                  let checked_lowest_amount_lay = 0;
                  _.map(JSON.parse(item.runners_odds), (odds) => {
                    _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                      if (key === 0) {
                        if (Number(o.price) > 1) {
                          if ((checked_lowest_amount_back != 0) && (Number(o.price) < checked_lowest_amount_back))
                            checked_lowest_amount_back = Number(o.price);
                          else if (checked_lowest_amount_back === 0)
                            checked_lowest_amount_back = Number(o.price);
                        }
                      }
                    });
                    _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                      if (key === 0) {
                        if (Number(o.price) > 1) {
                          if ((checked_lowest_amount_lay != 0) && (Number(o.price) < checked_lowest_amount_lay))
                            checked_lowest_amount_lay = Number(o.price);
                          else if (checked_lowest_amount_lay === 0)
                            checked_lowest_amount_lay = Number(o.price);
                        }
                      }
                    });
                  });
                  return (
                    (Number(item.market_type) === 1) && (Number(item.settlement_status) === 0) &&
                    <Fragment key={key}>
                      <div className="mb-2 market-container-new layback-odd-new">
                        <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
                          <Col className='d-flex align-items-center col-8'>
                            <div className="col-sm-11 col-md-12 col-lg-">
                              <div className={"d-block text-uppercase text-nowrap  text-white " + (is_betslip_drawer ? "font11" : "font18")}>
                                {/* {Number(item.market_type) === 2 ? "Bookmaker" : item.market_name}  */}
                                {Number(item.is_main_market) == 1 ? item.market_name :
                                  Number(item.market_type) === 1 && item.market_name == "Match Odds" ? item.market_name
                                    : Number(item.is_diamond_feed) == 1 ? "MATCH ODDS 1 (FAST BET)"
                                      : Number(item.is_sky_feed) == 1 ? "MATCH ODDS 2 (FAST BET)"
                                        : Number(item.is_central_feed) == 1 ? "MATCH ODDS 3 (FAST BET)"
                                          : "MATCH ODDS"}{" "}{Number(item.is_manual_market) == 1 && "(M)"}

                              </div>
                              <div className={"min_max_price   " + (is_betslip_drawer ? "font9" : "font13")}>{`(Min : ${item.min_odd}  Max : ${item.max_odd})`}</div>
                            </div>
                          </Col>

                          <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                            <span>LAGAI</span>
                          </Col>
                          <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                            <span>KHAI</span>

                          </Col>
                          {/* <Col className="col-1">
                                <span className='position-absolute '><i className={"pointer float-right mt13 icon-down " + (is_betslip_drawer ? "ml-2" : "ml-100")}></i></span>
                                </Col> */}
                        </Row>

                        {
                          _.map(JSON.parse(item.runners), (runner, key) => {
                            var favoriteTeam = '';
                            var lock_team_betting = false;
                            if (item.team_bet_setting && !_.isNull(item.team_bet_setting)) {
                              _.map(JSON.parse(item.team_bet_setting), (itm, indx) => {
                                if (itm.selectionId == runner.selectionId)
                                  lock_team_betting = Number(itm.lock_team_betting) === 1;
                              });
                            }
                            let back_zero = false;
                            let lay_zero = false;
                            _.map(JSON.parse(item.runners_odds), (odds) => {
                              if (odds.selectionId == runner.selectionId) {
                                _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                                  if (key === 0) {
                                    if (Number(o.price) > 1) {
                                      if ((checked_lowest_amount_back == Number(o.price)))
                                        favoriteTeam = odds.selectionId;
                                    }
                                  }
                                });
                                _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                                  if (key === 0) {
                                    if (Number(o.price) > 1) {
                                      if ((checked_lowest_amount_lay == Number(o.price)))
                                        favoriteTeam = odds.selectionId;
                                    }
                                  }
                                });
                              }
                            });
                            // var lock_team_betting = true;
                            // if (item.team_bet_setting && !_.isNull(item.team_bet_setting)) {
                            //   _.map(JSON.parse(item.team_bet_setting), (itm, indx) => {
                            //     if (itm.selectionId == runner.selectionId)
                            //       lock_team_betting = Number(itm.lock_team_betting) == 0 ? true : false;
                            //   });
                            // }
                            let odds_exist = false;
                            _.map(JSON.parse(item.runners_odds), (odds, key) => {
                              if (odds.selectionId == runner.selectionId)
                                odds_exist = true;
                            });
                            return (
                              marketStatus &&
                              <Row className={"bg-white py-1 mx-0 border-new layback-row align-items-center " + (isThemeGradient ? " bg-gradient-white " : "")} {...{ key }}>
                                <Col className='d-flex align-items-center col-8 p-0'>
                                  <div className="col-sm-11 col-md-12 col-lg-8">
                                    <div className={"d-block text-nowrap font-weight-bold text-dark " + (is_betslip_drawer ? "font10" : "font16")}>{runner.runnerName}</div>
                                    {
                                      (currentUser && !_.isUndefined(runner.winloss)) &&
                                      <Fragment>
                                        {
                                          _.isUndefined(runner.temp_winloss) ?
                                            <div {...{ className: ` winloss-${Math.sign(runner.winloss)}` + (is_betslip_drawer ? " font11 mtneg2" : " font17") }} >
                                              {_.round(runner.winloss, 2).toFixed(2)}
                                            </div>
                                            :
                                            <div {...{ className: ` winloss-${Math.sign(runner.temp_winloss)}`(is_betslip_drawer ? " font11 mtneg2" : " font17") }} >
                                              {_.round(runner.temp_winloss, 2).toFixed(2)}
                                            </div>
                                        }
                                      </Fragment>
                                    }
                                  </div>
                                </Col>

                                {
                                  odds_exist ?
                                    _.map(JSON.parse(item.runners_odds), (odds, key) => {
                                      return (
                                        odds.selectionId == runner.selectionId &&
                                        <Fragment {...{ key }}>

                                          <Col className={"col-2 cell p-1 " + (!is_betslip_drawer && "pr-3")}>
                                            <div className={" back-odd text-center" + (is_betslip_drawer ? " h30 " : "") + (isThemeGradient ? " bg-gradient-odd " : "")}>

                                              {
                                                _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                                                  if (!lock_team_betting && (key === 0))
                                                    back_zero = (Number(o.price) === 0);
                                                  return (
                                                    key === 0 &&

                                                    <div {...{ className: `my-auto ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_back_${key}` })} ${this._oddsActiveClass(odds.selectionId, item.market_event_id, 1) ? 'yellow' : ''}`, key }}
                                                      //   onClick={
                                                      //     lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                      //       ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o, EventDetails: EventDetails }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null}
                                                      // >
                                                      onClick={
                                                        Number(item.lock_back_betting) === 0 ?
                                                          (Number(item.is_manual_market) == 1 ? item.lock_back_betting && !lock_team_betting && key === 0 &&
                                                            !_.isUndefined(o.price) && Number(o.price) > 0
                                                            ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null :
                                                            item.lock_back_betting && !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                              ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null
                                                          )
                                                          : ''}>
                                                      <div className={is_betslip_drawer ? "font11" : ""}>{(Number(item.market_type) === 2 ? Utils.RemovedDecimalVal((Number(o.price) >= 1 ? ((Number(o.price) - 1) * 100) : (Number(o.price) * 100))) : Utils.TwoDecimalVal((Number(o.price) >= 1 ? (o.price - 1) : o.price))) || '--'}</div>
                                                      <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{(!_.isUndefined(o.size) && !_.isNull(o.size) && (o.size !== '') ? o.size : 0)}</div>
                                                    </div>

                                                  )
                                                })}
                                            </div>
                                          </Col>


                                          <Col className="col-2 p-1 cell">
                                            <div className={" lay-odd text-center " + (is_betslip_drawer ? " h30 " : "") + (isThemeGradient ? " bg-gradient-lay " : "")}>

                                              {
                                                item.market_name.toLowerCase() !== "to win the toss" &&
                                                _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                                                  if (!lock_team_betting && (key === 0))
                                                    lay_zero = (Number(o.price) === 0);
                                                  return (
                                                    key === 0 &&
                                                    <div {...{ className: `my-auto ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_lay_${key}` })} ${this._oddsActiveClass(odds.selectionId, item.market_event_id, 2) ? 'yellow' : ''}`, key }}
                                                      // onClick={
                                                      //   lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                      //     ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o, EventDetails: EventDetails }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null}>

                                                      onClick={
                                                        Number(item.lock_lay_betting) === 0 ?
                                                          (Number(item.is_manual_market) == 1 ? !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                            ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null :
                                                            !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                              ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null
                                                          ) : ''}>
                                                      <div className={is_betslip_drawer ? "font11" : ""}>{(Number(item.market_type) === 2 ? Utils.RemovedDecimalVal((Number(o.price) >= 1 ? ((Number(o.price) - 1) * 100) : (Number(o.price) * 100))) : Utils.TwoDecimalVal((Number(o.price) >= 1 ? (o.price - 1) : o.price))) || '--'}</div>
                                                      <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{(!_.isUndefined(o.size) && !_.isNull(o.size) && (o.size !== '') ? o.size : 0)}</div>
                                                      {
                                                        (Number(o.price) > 50) &&
                                                        <div className="game-status-50"><span>{"BET NOT ALLOWED"}</span></div>
                                                      }
                                                    </div>
                                                  )
                                                })}
                                            </div>
                                          </Col>

                                        </Fragment>
                                      )
                                    })
                                    :
                                    <Fragment>
                                      <Col className={"col-2 cell p-1 " + (!is_betslip_drawer && "pr-3")}>
                                        <div className={" back-odd text-center" + (is_betslip_drawer ? " h30 " : "")}>
                                          <div className="my-auto">
                                            <div className={is_betslip_drawer ? "font11" : ""}></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="col-2 p-1 cell">
                                        <div className={" lay-odd text-center " + (is_betslip_drawer ? " h30 " : "")}>
                                          <div className="my-auto">
                                            <div className={is_betslip_drawer ? "font11" : ""}></div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Fragment>

                                }


                                {Number(item.lock_back_betting) === 1 &&
                                  <div className={"game-status-odd " + (is_betslip_drawer ? " font8 " : " font13")}>
                                    <span>
                                      <img src={Images.PAD_LOCK} alt="lock" className={"pad_lock "} />
                                    </span>
                                  </div>
                                }
                                {Number(item.lock_lay_betting) === 1 &&
                                  <div className={"game-status-lay " + (is_betslip_drawer ? " font8 " : " font13")}>
                                    <span>
                                      <img src={Images.PAD_LOCK} alt="lock" className={"pad_lock "} />
                                    </span>
                                  </div>
                                }


                                {(Number(item.commentary_btnid) == 7) ?
                                  <div className="game-status">
                                    <span>Ball Start</span>
                                  </div> :
                                  (Number(item.commentary_btnid) == 3) ?
                                    <div className="game-status">
                                      <span>Bet Close</span>
                                    </div>
                                    :
                                    odds_exist == false ?
                                      <Fragment>
                                        {!_.isNull(item.status) && !_.isEmpty(item.status) && !Number(item.status) && item.status !== 'OPEN' ?
                                          <div className="game-status">
                                            <span>{item.status}</span>
                                          </div>
                                          :
                                          <div className="game-status">
                                            <span>{EventDetails.is_live == 1 ? ((Number(item.status) == 0 || item.status == 'OPEN' || Number(item.status) == 1) ? "MARKET SUSPENDED" : "BALL START") : "INACTIVE"}</span>
                                          </div>
                                        }
                                      </Fragment>
                                      :
                                      Number(item.lock_betting) === 1 ?
                                        <div className="game-status"><span>{"BET NOT ALLOWED"}</span></div>
                                        :
                                        <Fragment>
                                          {
                                            (!lock_team_betting && back_zero && lay_zero) ?
                                              <div className="game-status"><span>{"BET NOT ALLOWED"}</span></div>
                                              :
                                              ((Number(item.odd_status) === 2) || (Number(item.odd_status) === 3) || (Number(item.odd_status) === 4) || (Number(item.odd_status) === 9)) ?
                                                <div className="game-status"><span>{(Number(item.odd_status) === 2 ? "MARKTE INACTIVE" : ((Number(item.odd_status) === 3 ? "MARKET SUSPENDED" : (Number(item.odd_status) === 4 ? "Closed" : "Ball Start"))))}</span></div>
                                                :
                                                (item.status === 'SUSPENDED') &&
                                                <div className="game-status"><span>{item.status}</span></div>
                                          }
                                        </Fragment>
                                }

                                {
                                  Number(EventDetails.sports_id) === 2 &&
                                  _.map(AppMasterData.commentary_buttons, (button, bkey) => {
                                    return (
                                      ((Number(EventDetails.commentary_button_id) !== 5) && (Number(EventDetails.commentary_button_id) === Number(button.button_id)) && (Number(button.bet_status) === 0) && (button.button_label != "Ball Start") && (Number(button.button_id) === 37 || Number(button.button_id) === 38)) &&
                                      <div className="game-betlock" key={bkey}><span>BET CLOSE</span></div>
                                    )
                                  })
                                }

                                {
                                  this.state.selectedBetslip == runner.selectionId && this.state.selectedMarketID == item.market_id &&
                                  <Betslip {...BetslipProps} min={item.min_odd} max={item.max_odd} delay={item.delay} maxProfit={item.max_profit} />
                                }
                              </Row>


                            )
                          })}

                        {!_.isEmpty(item.announcement) && <Row className={"justify-content-center align-items-center p-2 announcement animate-pulse " + (isThemeGradient ? "bg-radial-1" : "")}>
                          <Col className="col-12">
                            <marquee scrollamount="3">{item.announcement}</marquee>
                          </Col>
                        </Row>}
                      </div>
                    </Fragment>
                  )
                })
              }
            </Collapse>
          </div>
        }


        {
          !_.isUndefined(MarketList) && !_.isNull(MarketList) && !_.isEmpty(MarketList) &&
          <div className="mb-2 market-container-new layback-odd-new">
            <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
              <Col className='d-flex align-items-center col-12 justify-content-between'>
                <div className={"d-block text-uppercase text-nowrap  text-white ml-4 " + (is_betslip_drawer ? "font11" : "font18")}>Bookmaker Market</div>
                <div className='mr-3'>
                  <span className={this.state.showBMMarket ? "icon-up" : "icon-down"} onClick={() => { this.setState({ showBMMarket: !this.state.showBMMarket }) }}></span>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={this.state.showBMMarket}>
              {
                _.map(MarketList, (item, key) => {
                  var marketStatus = true;
                  _.map(this.state.exchangeMarketShow, status => {
                    if (status.market_event_id == item.market_event_id)
                      marketStatus = status.show;
                  })

                  let checked_lowest_amount_back = 0;
                  let checked_lowest_amount_lay = 0;
                  _.map(JSON.parse(item.runners_odds), (odds) => {
                    _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                      if (key === 0) {
                        if (Number(o.price) > 1) {
                          if ((checked_lowest_amount_back != 0) && (Number(o.price) < checked_lowest_amount_back))
                            checked_lowest_amount_back = Number(o.price);
                          else if (checked_lowest_amount_back === 0)
                            checked_lowest_amount_back = Number(o.price);
                        }
                      }
                    });
                    _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                      if (key === 0) {
                        if (Number(o.price) > 1) {
                          if ((checked_lowest_amount_lay != 0) && (Number(o.price) < checked_lowest_amount_lay))
                            checked_lowest_amount_lay = Number(o.price);
                          else if (checked_lowest_amount_lay === 0)
                            checked_lowest_amount_lay = Number(o.price);
                        }
                      }
                    });
                  });
                  return (
                    (Number(item.market_type) === 2) && (Number(item.settlement_status) === 0) &&
                    <Fragment key={key}>
                      <div className="mb-2 market-container-new layback-odd-new ">
                        <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
                          <Col className='d-flex align-items-center col-8'>
                            <div className="col-sm-11 col-md-12 col-lg-">
                              <div className={"d-block text-uppercase text-nowrap  text-white " + (is_betslip_drawer ? "font11" : "font18")}>
                                {/* {Number(item.market_type) === 2 ? (item.is_manual_odds == 1 ? "Bookmaker 2 (Fast Bet 0 Sec)" : "Bookmaker (Fast Bet 0 Sec)") : item.market_name} */}
                                {Number(item.is_main_market) == 1 ? item.market_name
                                  : Number(item.is_diamond_feed) == 1 ? "BOOKMAKER 1"
                                    : Number(item.is_sky_feed) == 1 ? "BOOKMAKER 2"
                                      : Number(item.is_central_feed) == 1 ? "BOOKMAKER 3"
                                        : "BOOKMAKER 0 COM"}{" "}{Number(item.is_manual_market) == 1 && "(M)"}
                              </div>
                              <div className={"min_max_price  " + (is_betslip_drawer ? "font9" : "font13")}>{`(Min : ${item.min_odd}  Max : ${item.max_odd})`}</div>
                            </div>
                          </Col>

                          <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                            <span>LAGAI</span>
                          </Col>
                          <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                            <span>KHAI</span>

                          </Col>
                        </Row>

                        {
                          _.map(JSON.parse(item.runners), (runner, key) => {
                            var favoriteTeam = '';
                            var lock_team_betting = false;
                            if (item.team_bet_setting && !_.isNull(item.team_bet_setting)) {
                              _.map(JSON.parse(item.team_bet_setting), (itm, indx) => {
                                if (itm.selectionId == runner.selectionId)
                                  lock_team_betting = Number(itm.lock_team_betting) === 1;
                              });
                            }
                            let back_zero = false;
                            let lay_zero = false;
                            _.map(JSON.parse(item.runners_odds), (odds) => {
                              if (odds.selectionId == runner.selectionId) {
                                _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                                  if (key === 0) {
                                    if (Number(o.price) > 1) {
                                      if ((checked_lowest_amount_back == Number(o.price)))
                                        favoriteTeam = odds.selectionId;
                                    }
                                  }
                                });
                                _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                                  if (key === 0) {
                                    if (Number(o.price) > 1) {
                                      if ((checked_lowest_amount_lay == Number(o.price)))
                                        favoriteTeam = odds.selectionId;
                                    }
                                  }
                                });
                              }
                            });
                            // var lock_team_betting = true;
                            // if (item.team_bet_setting && !_.isNull(item.team_bet_setting)) {
                            //   _.map(JSON.parse(item.team_bet_setting), (itm, indx) => {
                            //     if (itm.selectionId == runner.selectionId)
                            //       lock_team_betting = Number(itm.lock_team_betting) == 0 ? true : false;
                            //   });
                            // }
                            let odds_exist = false;
                            _.map(JSON.parse(item.runners_odds), (odds, key) => {
                              if (odds.selectionId == runner.selectionId)
                                odds_exist = true;
                            });
                            return (
                              marketStatus &&

                              <Row className={"bg-white py-1 mx-0 border-new layback-row align-items-center " + (isThemeGradient ? " bg-gradient-white " : "")} {...{ key }}>
                                <Col className='d-flex align-items-center col-8 p-0'>
                                  <div className="col-sm-11 col-md-12 col-lg-8">
                                    <div className={"d-block text-nowrap font-weight-bold text-dark " + (is_betslip_drawer ? "font11" : "font16")}>{runner.runnerName} </div>

                                    {
                                      (currentUser && !_.isUndefined(runner.winloss)) &&
                                      <Fragment>
                                        {
                                          _.isUndefined(runner.temp_winloss) ?
                                            <div className={`winloss-${Math.sign(runner.winloss)} ` + (is_betslip_drawer ? " font11 mtneg2" : " font17")}>{_.round(runner.winloss, 2).toFixed(2)}</div>
                                            :
                                            <div className={`winloss-${Math.sign(runner.temp_winloss)} ` + (is_betslip_drawer ? " font11 mtneg2" : " font17")}> {_.round(runner.temp_winloss, 2).toFixed(2)}</div>
                                        }
                                      </Fragment>}
                                  </div>
                                </Col>
                                {
                                  odds_exist ?
                                    _.map(JSON.parse(item.runners_odds), (odds, key) => {
                                      return (
                                        odds.selectionId == runner.selectionId &&
                                        <Fragment {...{ key }}>
                                          <Col className={"col-2 cell p-1 " + (!is_betslip_drawer && "pr-3")}>
                                            <div className={" back-odd text-center" + (is_betslip_drawer ? " h30 " : "") + (isThemeGradient ? " bg-gradient-odd " : "")}>
                                              {
                                                _.map(Utils.getSelectedOdds(odds, 1), (o, key) => {
                                                  if (!lock_team_betting && (key === 0))
                                                    back_zero = (Number(o.price) === 0);
                                                  return (
                                                    key === 0 &&
                                                    <div {...{ className: `my-auto ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_back_${key}` })} ${this._oddsActiveClass(odds.selectionId, item.market_event_id, 1) ? 'yellow' : ''}`, key }}
                                                      //   onClick={
                                                      //     lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                      //       ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o, EventDetails: EventDetails }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null}
                                                      // >
                                                      onClick={
                                                        Number(item.lock_back_betting) === 0 ?
                                                          (Number(item.is_manual_market) == 1 ? item.lock_back_betting && !lock_team_betting && key === 0 &&
                                                            !_.isUndefined(o.price) && Number(o.price) > 0
                                                            ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null :
                                                            item.lock_back_betting && !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                              ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 1, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null
                                                          )
                                                          : ''}>
                                                      <div className={is_betslip_drawer ? "font11" : ""}>{(Number(item.market_type) === 2 ? Utils.TwoDecimalVal((Number(o.price) >= 1 ? ((Number((100 * o.price) / 100) - 1) * 100) : (Number((100 * o.price) / 100) * 100))) : Utils.TwoDecimalVal((Number(o.price) >= 1 ? (o.price - 1) : o.price))) || '--'}</div>
                                                      <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{(!_.isUndefined(o.size) && !_.isNull(o.size) && (o.size !== '') ? o.size : 0)}</div>
                                                    </div>
                                                  )
                                                })}

                                            </div>
                                          </Col>



                                          <Col className="col-2 p-1 cell">
                                            <div className={" lay-odd text-center " + (is_betslip_drawer ? " h30 " : "") + (isThemeGradient ? " bg-gradient-lay " : "")}>
                                              {
                                                item.market_name.toLowerCase() !== "to win the toss" &&
                                                _.map(Utils.getSelectedOdds(odds, 2), (o, key) => {
                                                  if (!lock_team_betting && (key === 0))
                                                    lay_zero = (Number(o.price) === 0);
                                                  return (
                                                    key === 0 &&

                                                    <div  {...{ className: `my-auto ${_.isUndefined(o.price) ? 'cursor-default' : ''} ${_.isUndefined(o.price) ? null : this.SocketHighlight({ id: `${odds.selectionId}_lay_${key}` })} ${this._oddsActiveClass(odds.selectionId, item.market_event_id, 2) ? 'yellow' : ''}`, key }}
                                                      //   onClick={
                                                      //     lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                      //       ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o, EventDetails: EventDetails }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null}
                                                      // >
                                                      onClick={
                                                        Number(item.lock_lay_betting) === 0 ?
                                                          (Number(item.is_manual_market) == 1 ? !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                            ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null :
                                                            !lock_team_betting && key === 0 && !_.isUndefined(o.price) && Number(o.price) > 0
                                                              ? () => [this.handleOptionChange({ fixture: { ...item, sports_id: EventDetails.sports_id }, odd: { ...odds, market_bet_id: odds.selectionId }, betting_type: 2, selected_odd: o }), this.ResetWinloss(item), this.openMarketBetSlip(item.market_id, odds.selectionId)] : null
                                                          ) : ''}>
                                                      <div className={is_betslip_drawer ? "font11" : ""}>{(Number(item.market_type) === 2 ? Utils.TwoDecimalVal((Number(o.price) >= 1 ? ((Number((100 * o.price) / 100) - 1) * 100) : (Number((100 * o.price) / 100) * 100))) : Utils.TwoDecimalVal((Number(o.price) >= 1 ? (o.price - 1) : o.price))) || '--'}</div>
                                                      <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{(!_.isUndefined(o.size) && !_.isNull(o.size) && (o.size !== '') ? o.size : 0)}</div>
                                                    </div>

                                                  )
                                                })}
                                            </div>
                                          </Col>

                                        </Fragment>
                                      )
                                    })
                                    :
                                    <Fragment>
                                      <Col className={"col-2 cell p-1 " + (!is_betslip_drawer && "pr-3")}>
                                        <div className={" back-odd text-center" + (is_betslip_drawer ? " h30" : "")}>
                                          <div className="my-auto">
                                            <div className={is_betslip_drawer ? "font11" : "font18"}></div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col className="col-2 p-1 cell">
                                        <div className={" lay-odd text-center " + (is_betslip_drawer ? " h30" : "")}>
                                          <div className="my-auto">
                                            <div className={is_betslip_drawer ? "font11" : "font18"}></div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Fragment>
                                }


                                {Number(item.lock_back_betting) === 1 &&
                                  <div className={"game-status-odd " + (is_betslip_drawer ? " font8 " : " font13")}>
                                    <span>
                                      <img src={Images.PAD_LOCK} alt="lock" className={"pad_lock "} />
                                    </span>
                                  </div>
                                }
                                {Number(item.lock_lay_betting) === 1 &&
                                  <div className={"game-status-lay " + (is_betslip_drawer ? " font8 " : " font13")}>
                                    <span>
                                      <img src={Images.PAD_LOCK} alt="lock" className={"pad_lock "} />
                                    </span>
                                  </div>
                                }



                                {(Number(item.commentary_btnid) == 7) ?
                                  <div className="game-status">
                                    <span>Ball Start</span>
                                  </div> :
                                  (Number(item.commentary_btnid) == 3) ?
                                    <div className="game-status">
                                      <span>Bet Close</span>
                                    </div>
                                    :
                                    odds_exist == false ?
                                      <Fragment>
                                        {!_.isNull(item.status) && !_.isEmpty(item.status) && !Number(item.status) && item.status !== 'OPEN' ?
                                          <div className="game-status">
                                            <span>{item.status}</span>
                                          </div>
                                          :
                                          <div className="game-status">
                                            <span>{EventDetails.is_live == 1 ? ((Number(item.status) == 0 || item.status == 'OPEN' || Number(item.status) == 1) ? "MARKET SUSPENDED" : "BALL START") : "INACTIVE"}</span>
                                          </div>
                                        }
                                      </Fragment>
                                      :
                                      Number(item.lock_betting) === 1 ?
                                        <div className="game-status"><span>{"BET NOT ALLOWED"}</span></div>
                                        :
                                        <Fragment>
                                          {
                                            (!lock_team_betting && back_zero && lay_zero) ?
                                              <div className="game-status"><span>{"BET NOT ALLOWED"}</span></div>
                                              :
                                              ((Number(item.odd_status) === 2) || (Number(item.odd_status) === 3) || (Number(item.odd_status) === 4) || (Number(item.odd_status) === 9)) ?
                                                <div className="game-status"><span>{(Number(item.odd_status) === 2 ? "MARKTE INACTIVE" : ((Number(item.odd_status) === 3 ? "MARKET SUSPENDED" : (Number(item.odd_status) === 4 ? "Closed" : "Ball Start"))))}</span></div>
                                                :
                                                (item.status === 'SUSPENDED') &&
                                                <div className="game-status"><span>{item.status}</span></div>
                                          }
                                        </Fragment>
                                }

                                {
                                  Number(EventDetails.sports_id) === 2 &&
                                  _.map(AppMasterData.commentary_buttons, (button, bkey) => {
                                    return (
                                      ((Number(EventDetails.commentary_button_id) !== 5) && (Number(EventDetails.commentary_button_id) === Number(button.button_id)) && (Number(button.bet_status) === 0) && (button.button_label != "Ball Start") && (Number(button.button_id) === 37 || Number(button.button_id) === 38)) &&
                                      <div className="game-betlock" key={bkey}><span>BET CLOSE</span></div>
                                    )
                                  })
                                }

                                {
                                  this.state.selectedBetslip == runner.selectionId && this.state.selectedMarketID == item.market_id && item.market_id == openBetSlipid &&
                                  <Betslip {...BetslipProps} min={item.min_odd} max={item.max_odd} delay={item.delay} maxProfit={item.max_profit} />
                                }
                              </Row>




                            )
                          })}


                        {!_.isEmpty(item.announcement) && <Row className={"justify-content-center align-items-center p-2 announcement animate-pulse " + (isThemeGradient ? "bg-radial-1" : "")}>
                          <Col className="col-12">
                            <marquee scrollamount="3">{item.announcement}</marquee>
                          </Col>
                        </Row>}
                      </div>
                      {/* // end new design */}



                    </Fragment>
                  )
                })
              }
            </Collapse>
          </div>
        }



        {SwitchModalShow && <SwitchModal {...SwitchModalProps} />}
      </Fragment>
    );
  }
}
const MarketFixtureDetailListTrans = withTranslation()(MarketFixtureDetailList)

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject
  };
}

export default connect(
  mapStateToProps
)(MarketFixtureDetailListTrans);
